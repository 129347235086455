import { makeRequest } from "@api/makeRequest";

import { parseAxiosError } from "@utils/parse-error";

import type {
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
} from "@frontend/kitui";

type RecoverResponse = {
  message: string;
};

const API_URL = `${process.env.REACT_APP_DOMAIN}/auth`;

export const register = async (
  login: string,
  password: string,
  phoneNumber: string,
): Promise<[RegisterResponse | null, string?]> => {
  try {
    const registerResponse = await makeRequest<
      RegisterRequest,
      RegisterResponse
    >({
      url: `${API_URL}/register`,
      body: { login, password, phoneNumber },
      useToken: false,
      withCredentials: true,
    });

    return [registerResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);
    console.error(error)

    return [null, `Не удалось зарегестрировать аккаунт. ${error}`];
  }
};

export const login = async (
  login: string,
  password: string,
): Promise<[LoginResponse | null, string?]> => {
  try {
    const loginResponse = await makeRequest<LoginRequest, LoginResponse>({
      url: `${API_URL}/login`,
      body: { login, password },
      useToken: false,
      withCredentials: true,
    });

    return [loginResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось войти. ${error}`];
  }
};

export const logout = async (): Promise<[void | null, string?]> => {
  try {
    const logoutResponse = await makeRequest<never, void>({
      method: "POST",
      url: `${API_URL}/logout`,
      useToken: true,
      withCredentials: true,
    });

    return [logoutResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось выйти. ${error}`];
  }
};

export const recover = async (
  email: string,
): Promise<[RecoverResponse | null, string?]> => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append(
      "RedirectionURL",
      `${process.env.REACT_APP_DOMAIN}/changepwd`,
    );

    const recoverResponse = await makeRequest<FormData, RecoverResponse>({
      url: `${process.env.REACT_APP_DOMAIN}/codeword/recover`,
      body: formData,
      useToken: false,
      withCredentials: true,
    });

    return [recoverResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось восстановить пароль. ${error}`];
  }
};
