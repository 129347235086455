import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface CartStore {
    cc: boolean
}
const initialState: CartStore = {
    cc: false
}

//Была попытка оплатить корзину. Тут записанна недостающая сумма.
export const useCC = create<CartStore>()(
    devtools(
        (get, set) => initialState,
        {
            name: "cc",
            enabled: process.env.NODE_ENV === "development",
            trace: true,
            actionsBlacklist: "rejected",
        }
    )
);

export const cancelCC = () => useCC.setState({ cc: false });
export const startCC = () => useCC.setState({ cc: true });