import Box from "@mui/material/Box";
import { CssBaseline, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import SectionStyled from "@/pages/Landing/SectionStyled";
import React, { useMemo } from "react";
import { TariffCard } from "@/pages/Landing/Tariffs/TariffCard/TariffCard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export type Tariff = {
  name: string;
  type: "requests" | "duration" | "trial";
  description: string;
  actualPrice: string;
  oldPrice?: string;
  discount?: string;
};

const swiperStyles = `
.mySwiper .swiper-pagination-bullet {
  background: #9A9AAF; 
  margin-top: 40px; 
}
.mySwiper .swiper-pagination {
  position: relative;
  margin-top: 30px;
}
.mySwiper .swiper-button-next, .mySwiper .swiper-button-prev {
  width: 37.5px;
  height: 37.5px;
  background: white;
  border-radius: 50%;
  margin-top: -40px;
}
.mySwiper .swiper-button-next:after, .mySwiper .swiper-button-prev:after {
  color: black;
  font-size: 20px;
}
`;

export const Tariffs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const tariffs: Tariff[] = useMemo(
    () => [
      {
        name: "Бесплатно 14 дней",
        type: "trial",
        description:
          "Каждому пользователю все наши продукты в первые 14 дней доступны совершенно бесплатно (кроме доп.услуг)",
        actualPrice: "0",
      },
      {
        name: "Месяц",
        type: "duration",
        description: "30 дней безлимитного пользования сервисом",
        actualPrice: "969",
        oldPrice: "1020",
        discount: "5",
      },
      {
        name: "3 месяца",
        type: "duration",
        description: "90 дней безлимитного пользования сервисом",
        actualPrice: " 2 325,6",
        oldPrice: "3060",
        discount: "24",
      },
      {
        name: "Год",
        type: "duration",
        description: "365 дней безлимитного пользования сервисом",
        actualPrice: " 7 501,84",
        oldPrice: "12 410",
        discount: "40",
      },
      {
        name: "3 года",
        type: "duration",
        description: "1095 дней безлимитного пользования сервисом",
        actualPrice: "16 939,65",
        oldPrice: "37 230",
        discount: "55",
      },
      {
        name: "100 Заявок",
        type: "requests",
        description: "Полное прохождение 100 опросов респондентом",
        actualPrice: "1 900",
        oldPrice: "2 000",
        discount: "5",
      },
      {
        name: "1 000 Заявок",
        type: "requests",
        description: "Полное прохождение 1000 опросов респондентом",
        actualPrice: "12 740",
        oldPrice: "20 000",
        discount: "36",
      },
      {
        name: "10 000 Заявок",
        type: "requests",
        description: "Полное прохождение 10000 опросов респондентом",
        actualPrice: "89 000",
        oldPrice: "200 000",
        discount: "56",
      },
    ],
    []
  );

  return (
    <SectionStyled
      tag={"section"}
      bg={"#282937"}
      sxsect={{ marginTop: "-40px", padding: isMobile ? "0 16px" : isTablet ? "0 40px" : "0 20px" }}
      mwidth={"1160px"}
      sxcont={{
        boxSizing: "border-box",
      }}
    >
      <CssBaseline>
        <style>{swiperStyles}</style>
      </CssBaseline>
      <Box
        color="#ffffff"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginTop: "140px",
          marginBottom: "40px",
        }}
      >
        <Typography variant="h6" fontSize="36px" lineHeight={1}>
          Наши тарифы
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "80px" }}>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={15}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className="mySwiper"
        >
          {tariffs.map((tariff) => {
            return (
              <SwiperSlide style={{ width: isMobile ? "321px" : "360px" }}>
                <TariffCard
                  name={tariff.name}
                  type={tariff.type}
                  description={tariff.description}
                  actualPrice={tariff.actualPrice}
                  discount={tariff.discount}
                  oldPrice={tariff.oldPrice}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </SectionStyled>
  );
};
