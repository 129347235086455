import { Header } from "@ui_kit/Header/Header";
import Sidebar from "@ui_kit/Sidebar/Sidebar";
import Box from "@mui/material/Box";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { SidebarMobile } from "../ui_kit/Sidebar/SidebarMobile";
import { setShowConfirmLeaveModal } from "@root/uiTools/actions";
import { setCurrentStep, setQuizes } from "@root/quizes/actions";
import { useQuizStore } from "@root/quizes/store";
import { SmallSwitchQuestionListGraph } from "@ui_kit/Toolbars/SmallSwitchQuestionListGraph";
import { PanelSwitchQuestionListGraph } from "@ui_kit/Toolbars/PanelSwitchQuestionListGraph";
import { ButtonTestPublication } from "@ui_kit/Toolbars/ButtonTestPublication";
import { ButtonRecallQuiz } from "@ui_kit/Toolbars/ButtonRecallQuiz";
import { Link } from "react-router-dom";
import { LinkSimple } from "@icons/LinkSimple";
import { useCurrentQuiz } from "@root/quizes/hooks";
import { deleteTimeoutedQuestions } from "@utils/deleteTimeoutedQuestions";
import { quizApi } from "@api/quiz";
import { questionApi } from "@api/question";
import { createResult, setQuestions } from "@root/questions/actions";
import { toggleQuizPreview } from "@root/quizPreview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDomainDefine } from "@utils/hooks/useDomainDefine";
// https://shub.pena.digital/quizpayment?action=squizpay&dif=9800&data=eyJhbGciOiJS
interface Props {
  sidebar: boolean;
  header?: boolean;
  footer?: boolean;
  Page?: React.Component;
}

export default function Main({ sidebar, header, footer, Page }: Props) {
  const theme = useTheme();
  const quiz = useCurrentQuiz();
  const quizConfig = quiz?.config;
  const { editQuizId } = useQuizStore();
  const currentStep = useQuizStore((state) => state.currentStep);
  const { isTestServer } = useDomainDefine();
  useEffect(() => {
    const getData = async () => {
      const [quizes] = await quizApi.getList();
      setQuizes(quizes);

      if (editQuizId) {
        const [questions] = await questionApi.getList({ quiz_id: editQuizId });

        setQuestions(questions);
        //Всегда должен существовать хоть 1 резулт - "line"
        if (
          !questions?.find(
            (q) =>
              (q.type === "result" && q.content.includes(':"line"')) ||
              q.content.includes(":'line'"),
          )
        ) {
          createResult(quiz?.backendId, "line");
        }
      }
    };
    getData();
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down(650));
  const isMobileSm = useMediaQuery(theme.breakpoints.down(370));
  const isBranchingLogicSmall = useMediaQuery(theme.breakpoints.down(1100));
  const isBranchingLogicBig = useMediaQuery(theme.breakpoints.down(1446));
  const isLinkButton = useMediaQuery(theme.breakpoints.down(708));
  const [mobileSidebar, setMobileSidebar] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<number>(0);
  const [openBranchingPage, setOpenBranchingPage] = useState<boolean>(false);

  const openBranchingPageHC = useCallback(() => {
    if (!openBranchingPage) {
      deleteTimeoutedQuestions();
    }
    setOpenBranchingPage((old) => !old);
  }, [openBranchingPage, setOpenBranchingPage]);

  const isConditionMet =
    [1].includes(currentStep) && quizConfig?.type !== "form";

  const changePage = (index: number) => {
    if (currentStep === 2) {
      setNextStep(index);
      setShowConfirmLeaveModal(true);

      return;
    }

    setCurrentStep(index);
  };

  const [widthMain, setWidthMain] = useState(null);
  const [heightSidebar, setHeightSidebar] = useState(null);
  const [scrollDown, setScrollDown] = useState<boolean | null>(null);
  const mainBlock = useRef(0);
  const heightHeader = heightSidebar + 51 + 36;
  const observer = useRef(
    new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setWidthMain(width);
    }),
  );

  useEffect(() => {
    if (mainBlock.current) observer.current.observe(mainBlock.current);
  }, [mainBlock, observer]);

  if (!quizConfig) return <></>;
  return (
    <>
      <Header setMobileSidebar={setMobileSidebar} scrollDown={scrollDown} />
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {sidebar ? (
          <>
            {isMobile ? (
              <SidebarMobile
                open={mobileSidebar}
                changePage={changePage}
                setHeightSitebar={setHeightSidebar}
                scrollDown={scrollDown}
              />
            ) : (
              <Sidebar changePage={changePage} />
            )}
          </>
        ) : (
          <></>
        )}
        <Box
          sx={{
            width: "100%",
            height: isMobile ? "100vh" : "calc(100vh - 80px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <Box
            ref={mainBlock}
            sx={{
              background: theme.palette.background.default,
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Page
              openBranchingPage={openBranchingPage}
              setOpenBranchingPage={openBranchingPageHC}
              widthMain={widthMain}
              mobileSidebar={mobileSidebar}
              heightSidebar={heightSidebar}
              scrollDown={scrollDown}
              setScrollDown={setScrollDown}
            />
          </Box>

          {footer && (
            <Box
              sx={{
                width: "100%",
                padding: isMobile ? "20px 16px" : "20px 20px",
                display: "flex",
                justifyContent: isMobile
                  ? isMobileSm
                    ? "center"
                    : "flex-end"
                  : "flex-start",
                flexDirection: isMobile ? "row-reverse" : "-moz-initial",
                alignItems: "center",
                gap: isMobile ? "5px" : "15px",
                background: "#FFF",
                borderTop: "#f2f3f7 2px solid",
                zIndex: 3,
                position: isMobile ? "fixed" : undefined,
                bottom: isMobile ? 0 : undefined,
              }}
            >
              {isConditionMet &&
                (isBranchingLogicSmall ? (
                  <SmallSwitchQuestionListGraph
                    openBranchingPage={openBranchingPage}
                    setOpenBranchingPage={openBranchingPageHC}
                  />
                ) : widthMain < 1216 || !openBranchingPage ? (
                  <PanelSwitchQuestionListGraph
                    openBranchingPage={openBranchingPage}
                    setOpenBranchingPage={openBranchingPageHC}
                    hideText
                  />
                ) : (
                  <></>
                ))}
              <ButtonTestPublication />
              <ButtonRecallQuiz />
              {quiz?.status === "start" &&
                (!isLinkButton ? (
                  <Box
                    data-cy="link-test"
                    component={Link}
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: isMobile ? "none" : "block",
                      color: "#7E2AEA",
                      fontSize: "14px",
                    }}
                    target="_blank"
                    to={
                      isTestServer
                        ? "https://s.hbpn.link/" + quiz.qid
                        : "https://hbpn.link/" + quiz.qid
                    }
                  >
                    {isTestServer
                      ? `https://s.hbpn.link/${quiz.qid}`
                      : `https://hbpn.link/${quiz.qid}`}
                  </Box>
                ) : (
                  <Box
                    data-cy="link-test"
                    component={Link}
                    sx={{
                      cursor: "pointer",
                      minWidth: "34px",
                      height: "34px",
                      color: "#7E2AEA",
                      fontSize: "14px",
                      display: isMobile ? "none" : "flex",
                      justifyContent: "center",
                      alignItems: "Center",
                      background: "#EEE4FC",
                      borderRadius: "8px",
                    }}
                    target="_blank"
                    to={
                      isTestServer
                        ? "https://s.hbpn.link/" + quiz.qid
                        : "https://hbpn.link/" + quiz.qid
                    }
                  >
                    <LinkSimple />
                  </Box>
                ))}
              {isMobile && quiz?.status === "start" && (
                <Box
                  data-cy="link-test"
                  component={Link}
                  sx={{
                    cursor: "pointer",
                    width: "34px",
                    height: "34px",
                    color: "#7E2AEA",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "Center",
                    background: "#EEE4FC",
                    borderRadius: "8px",
                  }}
                  target="_blank"
                  to={
                    isTestServer
                      ? "https://s.hbpn.link/" + quiz.qid
                      : "https://hbpn.link/" + quiz.qid
                  }
                >
                  <LinkSimple />
                </Box>
              )}
              {currentStep <= 1 && (
                <Box sx={{ textAlign: "end", width: "100%" }}>
                  <IconButton
                    data-cy="button-preview"
                    onClick={toggleQuizPreview}
                    sx={{
                      pointerEvents: "auto",
                      marginLeft: "auto",
                      position: "relative",
                      zIndex: "999999",
                      alignItems: "end",
                    }}
                  >
                    <VisibilityIcon sx={{ height: "30px", width: "30px" }} />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
